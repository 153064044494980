import { FC } from 'react';

import classNames from 'classnames';

interface Props {
   icon: string;
   type?: 'brand' | 'flag' | 'default';
   size?: 'heading-1' | 'heading-2' | 'heading-3' | 'heading-4' | 'headline' | 'body' | 'body-highlight' | 'small' | 'small-highlight' | 'caption';
   color?: 'primary' | 'secondary' | 'tertiary' | 'strong' | 'subtle' | 'weak' | 'inverse-strong' | 'inverse-subtle' | 'inverse-weak' | 'error' | 'inherit';
}

const flagMapping: {
   [key: string]: string;
} = {
   au: 'https://halo.ravago.com/assets/country-flags/au.svg',
   at: 'https://halo.ravago.com/assets/country-flags/at.svg',
   by: 'https://halo.ravago.com/assets/country-flags/by.svg',
   be: 'https://halo.ravago.com/assets/country-flags/be.svg',
   ba: 'https://halo.ravago.com/assets/country-flags/ba.svg',
   bg: 'https://halo.ravago.com/assets/country-flags/bg.svg',
   hr: 'https://halo.ravago.com/assets/country-flags/hr.svg',
   cz: 'https://halo.ravago.com/assets/country-flags/cz.svg',
   dk: 'https://halo.ravago.com/assets/country-flags/dk.svg',
   ee: 'https://halo.ravago.com/assets/country-flags/ee.svg',
   fi: 'https://halo.ravago.com/assets/country-flags/fi.svg',
   fr: 'https://halo.ravago.com/assets/country-flags/fr.svg',
   de: 'https://halo.ravago.com/assets/country-flags/de.svg',
   gr: 'https://halo.ravago.com/assets/country-flags/gr.svg',
   hu: 'https://halo.ravago.com/assets/country-flags/hu.svg',
   ie: 'https://halo.ravago.com/assets/country-flags/ie.svg',
   it: 'https://halo.ravago.com/assets/country-flags/it.svg',
   xk: 'https://halo.ravago.com/assets/country-flags/xk.svg',
   lu: 'https://halo.ravago.com/assets/country-flags/lu.svg',
   lv: 'https://halo.ravago.com/assets/country-flags/lv.svg',
   lt: 'https://halo.ravago.com/assets/country-flags/lt.svg',
   nl: 'https://halo.ravago.com/assets/country-flags/nl.svg',
   mk: 'https://halo.ravago.com/assets/country-flags/mk.svg',
   no: 'https://halo.ravago.com/assets/country-flags/no.svg',
   pl: 'https://halo.ravago.com/assets/country-flags/pl.svg',
   pt: 'https://halo.ravago.com/assets/country-flags/pt.svg',
   ro: 'https://halo.ravago.com/assets/country-flags/ro.svg',
   ru: 'https://halo.ravago.com/assets/country-flags/ru.svg',
   rs: 'https://halo.ravago.com/assets/country-flags/rs.svg',
   sk: 'https://halo.ravago.com/assets/country-flags/sk.svg',
   si: 'https://halo.ravago.com/assets/country-flags/si.svg',
   za: 'https://halo.ravago.com/assets/country-flags/za.svg',
   es: 'https://halo.ravago.com/assets/country-flags/es.svg',
   se: 'https://halo.ravago.com/assets/country-flags/se.svg',
   ch: 'https://halo.ravago.com/assets/country-flags/ch.svg',
   tr: 'https://halo.ravago.com/assets/country-flags/tr.svg',
   ua: 'https://halo.ravago.com/assets/country-flags/ua.svg',
   gb: 'https://halo.ravago.com/assets/country-flags/gb.svg',
   us: 'https://halo.ravago.com/assets/country-flags/us.svg',
   kz: 'https://halo.ravago.com/assets/country-flags/kz.svg'
};

const typographyMapping: { [key: string]: string } = {
   'heading-1': 'phone:typography-component-data-display-icon-glyph-mobile-h1 tab-port:typography-component-data-display-icon-glyph-tablet-h1 tab-land:typography-component-data-display-icon-glyph-tablet-h1 typography-component-data-display-icon-glyph-desktop-h1 ',
   'heading-2': 'phone:typography-component-data-display-icon-glyph-mobile-h2 tab-port:typography-component-data-display-icon-glyph-tablet-h2 tab-land:typography-component-data-display-icon-glyph-tablet-h2 typography-component-data-display-icon-glyph-desktop-h2',
   'heading-3': 'phone:typography-component-data-display-icon-glyph-mobile-h3 tab-port:typography-component-data-display-icon-glyph-tablet-h3 tab-land:typography-component-data-display-icon-glyph-tablet-h3 typography-component-data-display-icon-glyph-desktop-h3',
   'heading-4': 'phone:typography-component-data-display-icon-glyph-mobile-h4 tab-port:typography-component-data-display-icon-glyph-tablet-h4 tab-land:typography-component-data-display-icon-glyph-tablet-h4 typography-component-data-display-icon-glyph-desktop-h4',
   headline: 'phone:typography-component-data-display-icon-glyph-mobile-headline tab-port:typography-component-data-display-icon-glyph-tablet-headline tab-land:typography-component-data-display-icon-glyph-tablet-headline typography-component-data-display-icon-glyph-desktop-headline',
   body: 'phone:typography-component-data-display-icon-glyph-mobile-body-regular tab-port:typography-component-data-display-icon-glyph-tablet-body-regular tab-land:typography-component-data-display-icon-glyph-tablet-body-regular typography-component-data-display-icon-glyph-desktop-body-regular',
   'body-highlight': 'phone:typography-component-data-display-icon-glyph-mobile-body-bold tab-port:typography-component-data-display-icon-glyph-tablet-body-bold tab-land:typography-component-data-display-icon-glyph-tablet-body-bold typography-component-data-display-icon-glyph-desktop-body-bold',
   small: 'phone:typography-component-data-display-icon-glyph-mobile-label-regular tab-port:typography-component-data-display-icon-glyph-tablet-label-regular tab-land:typography-component-data-display-icon-glyph-tablet-label-regular typography-component-data-display-icon-glyph-desktop-label-regular',
   'small-highlight':
      'phone:typography-component-data-display-icon-glyph-mobile-label-bold tab-port:typography-component-data-display-icon-glyph-tablet-label-bold tab-land:typography-component-data-display-icon-glyph-tablet-label-bold typography-component-data-display-icon-glyph-desktop-label-bold',
   caption:
      'phone:typography-component-data-display-icon-glyph-mobile-caption-regular tab-port:typography-component-data-display-icon-glyph-tablet-caption-regular tab-land:typography-component-data-display-icon-glyph-tablet-caption-regular typography-component-data-display-icon-glyph-desktop-caption-regular'
};

const containerSizeMapping: { [key: string]: string } = {
   'heading-1': 'phone:w-component-data-display-icon-container-sizing-width-mobile-h1 tab-port:w-component-data-display-icon-container-sizing-width-tablet-h1 tab-land:w-component-data-display-icon-container-sizing-width-tablet-h1 w-component-data-display-icon-container-sizing-width-desktop-h1',
   'heading-2': 'phone:w-component-data-display-icon-container-sizing-width-mobile-h2 tab-port:w-component-data-display-icon-container-sizing-width-tablet-h2 tab-land:w-component-data-display-icon-container-sizing-width-tablet-h2 w-component-data-display-icon-container-sizing-width-desktop-h2',
   'heading-3': 'phone:w-component-data-display-icon-container-sizing-width-mobile-h3 tab-port:w-component-data-display-icon-container-sizing-width-tablet-h3 tab-land:w-component-data-display-icon-container-sizing-width-tablet-h3 w-component-data-display-icon-container-sizing-width-desktop-h3',
   'heading-4': 'phone:w-component-data-display-icon-container-sizing-width-mobile-h4 tab-port:w-component-data-display-icon-container-sizing-width-tablet-h4 tab-land:w-component-data-display-icon-container-sizing-width-tablet-h4 w-component-data-display-icon-container-sizing-width-desktop-h4',
   headline:
      'phone:w-component-data-display-icon-container-sizing-width-mobile-headline tab-port:w-component-data-display-icon-container-sizing-width-tablet-headline tab-land:w-component-data-display-icon-container-sizing-width-tablet-headline w-component-data-display-icon-container-sizing-width-desktop-headline',
   body: 'phone:w-component-data-display-icon-container-sizing-width-mobile-body tab-port:w-component-data-display-icon-container-sizing-width-tablet-body tab-land:w-component-data-display-icon-container-sizing-width-tablet-body w-component-data-display-icon-container-sizing-width-desktop-body',
   'body-highlight':
      'phone:w-component-data-display-icon-container-sizing-width-mobile-body tab-port:w-component-data-display-icon-container-sizing-width-tablet-body tab-land:w-component-data-display-icon-container-sizing-width-tablet-body w-component-data-display-icon-container-sizing-width-desktop-body',
   small: 'phone:w-component-data-display-icon-container-sizing-width-mobile-label tab-port:w-component-data-display-icon-container-sizing-width-tablet-label tab-land:w-component-data-display-icon-container-sizing-width-tablet-label w-component-data-display-icon-container-sizing-width-desktop-label',
   'small-highlight':
      'phone:w-component-data-display-icon-container-sizing-width-mobile-label tab-port:w-component-data-display-icon-container-sizing-width-tablet-label tab-land:w-component-data-display-icon-container-sizing-width-tablet-label w-component-data-display-icon-container-sizing-width-desktop-label',
   caption:
      'phone:w-component-data-display-icon-container-sizing-width-mobile-caption tab-port:w-component-data-display-icon-container-sizing-width-tablet-caption tab-land:w-component-data-display-icon-container-sizing-width-tablet-caption w-component-data-display-icon-container-sizing-width-desktop-caption'
};

export const Icon: FC<Props> = ({ icon, type = 'default', size = 'body', color = 'inherit' }) => (
   <div
      data-testid={`icon${type !== 'default' ? `--${type}` : ''}--${size}--${color}`}
      className={classNames(
         'inline-flex',
         color === 'strong' && 'text-component-data-display-icon-glyph-color-text-default-strong',
         color === 'subtle' && 'text-component-data-display-icon-glyph-color-text-default-subtle',
         color === 'weak' && 'text-component-data-display-icon-glyph-color-text-default-weak',
         color === 'inverse-strong' && 'text-component-data-display-icon-glyph-color-text-on-primary-strong',
         color === 'inverse-subtle' && 'text-component-data-display-icon-glyph-color-text-on-primary-subtle',
         color === 'inverse-weak' && 'text-component-data-display-icon-glyph-color-text-on-primary-weak',
         color === 'error' && 'text-component-data-display-icon-glyph-color-text-error',
         color === 'primary' && 'text-component-data-display-icon-glyph-color-text-primary',
         color === 'secondary' && 'text-component-data-display-icon-glyph-color-text-secondary',
         color === 'tertiary' && 'text-component-data-display-icon-glyph-color-text-tertiary'
      )}
   >
      <div data-testid="icon-container" className={classNames(containerSizeMapping[size], 'inline-flex items-center justify-center', type === 'flag' && 'aspect-[4/3] bg-contain bg-no-repeat')} style={{ ...(type === 'flag' && { backgroundImage: `url('${flagMapping[icon.toLowerCase()]}')` }) }}>
         {type !== 'flag' && <i data-testid="icon-glyph" className={`${type === 'brand' ? '!font-fa-brands' : ''} ${typographyMapping[size]} fa-${icon}`.replace(/\s+/g, ' ')} />}
      </div>
   </div>
);
