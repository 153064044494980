'use client';

import { PropsWithChildren, useContext, useMemo } from 'react';

import { TranslationClientContext } from '../context/translation-client-context';
import translate from '../utils/translation/translation.utils';

interface Props extends PropsWithChildren {
   translations: Record<string, any> | null;
   fallback: Record<string, any> | null;
}

export const TranslationClientContextProvider = ({ translations, fallback, children }: Props) => {
   const value = useMemo(() => ({ translations, fallback }), [translations, fallback]);

   return <TranslationClientContext.Provider value={value}>{children}</TranslationClientContext.Provider>;
};

export const useTranslations = () => {
   const data = useContext(TranslationClientContext);

   return (key: string, placeholders?: { [key: string]: any }, forceFallback?: boolean) =>
      translate(key, data?.translations || null, data?.fallback || null, placeholders, forceFallback);
};
