import { FC } from 'react';

import { Link as LinkBase, LinkProps } from '@ravago/shared/radiance/components/link/components/Link/Link';

import NextLink, { LinkProps as NextLinkProps } from 'next/link';

type AllNextLinkProps = LinkProps & NextLinkProps;

type Props = AllNextLinkProps | (LinkProps & { onClick: React.MouseEventHandler<HTMLAnchorElement> });

export const Link: FC<Props> = ({ ...props }) => {
   if (props.href === undefined) return <LinkBase {...props} />;

   // HACK: Prevent prefetch on all my (Angular) pages, to be removed when Angular is phased out
   if (props.href.includes('/my/')) (props as AllNextLinkProps).prefetch = false;

   return <LinkBase component={NextLink} {...props} />;
};
