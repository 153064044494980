import { capitalize } from '@mui/material';

export const getLanguageCode = (locale: string) => locale.split('-')[0].toLowerCase();

export const getCountryCode = (locale: string) => locale.split('-')[1]?.toUpperCase();

export const getLanguageNameFromLanguageCode = (languageCode: string, locale = 'en-US') => {
   const region = new Intl.DisplayNames(locale, { type: 'language' });
   const languageName = region.of(languageCode) ?? '';
   return capitalize(languageName);
};
