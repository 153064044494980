import { FC, ReactNode, useMemo } from 'react';

const screens = {
   phone: { min: 0, max: 599 },
   'tab-port': { min: 600, max: 904 },
   'tab-land': { min: 905, max: 1239 },
   desktop: { min: 1240 }
};

export type Breakpoint = keyof typeof screens;

export interface Props {
   includeViews?: Array<Breakpoint>;
   excludeViews?: Array<Breakpoint>;
   useContainerQuery?: boolean;
   children: ReactNode;
}

export const BreakpointSwitch: FC<Props> = ({ includeViews, excludeViews, useContainerQuery = false, children }) => {
   const availableViews = useMemo(() => {
      const allViews = Object.keys(screens) as Array<Breakpoint>;
      return allViews.filter((view) => {
         if (includeViews) return includeViews.includes(view);
         if (excludeViews) return !excludeViews.includes(view);

         return true;
      });
   }, [includeViews, excludeViews]);

   /* istanbul ignore next */
   return (
      <div
         data-testid={`breakpoint-switch--${availableViews.join('-')}${useContainerQuery ? '--container-query' : ''}`}
         className={`
               ${
                  !useContainerQuery
                     ? `${availableViews.includes('phone') ? 'phone:contents' : 'phone:hidden'}
                            ${availableViews.includes('tab-port') ? 'tab-port:contents' : 'tab-port:hidden'}
                            ${availableViews.includes('tab-land') ? 'tab-land:contents' : 'tab-land:hidden'}
                            ${availableViews.includes('desktop') ? 'desktop:contents' : 'desktop:hidden'}`
                     : `${availableViews.includes('phone') ? '@phone:contents' : '@phone:hidden'}
                            ${availableViews.includes('tab-port') ? '@tab-port:contents' : '@tab-port:hidden'}
                            ${availableViews.includes('tab-land') ? '@tab-land:contents' : '@tab-land:hidden'}
                            ${availableViews.includes('desktop') ? '@desktop:contents' : '@desktop:hidden'}`
               }`.replace(/\s+/g, ' ')}
      >
         {children}
      </div>
   );
};
