/* c8 ignore start */
export default function translate(
   key: string,
   translations: Record<string, any> | null,
   fallback: Record<string, any> | null,
   placeholders?: { [key: string]: any },
   forceFallback?: boolean
): string {
   if (!translations && !fallback) return key;

   let translation = key.split('.').reduce((record, index) => record?.[index] as never, translations || {}) as unknown as string;

   /**
    * If the translation is not found or forceFallback is true, try to find it in the fallback translations.
    */
   if (!translation || forceFallback) {
      translation = key.split('.').reduce((record, index) => record?.[index] as never, fallback || {}) as unknown as string;
   }

   if (!translation) return key;

   if (!placeholders) return translation;

   return Object.keys(placeholders).reduce((acc, placeholderKey) => acc.replace(`{{${placeholderKey}}}`, placeholders[placeholderKey]), translation);
}
/* c8 ignore stop */
