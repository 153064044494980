import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface Props {
   align?: 'left' | 'center' | 'right';
   color?: 'primary' | 'secondary' | 'tertiary' | 'strong' | 'subtle' | 'weak' | 'inverse-strong' | 'inverse-subtle' | 'inverse-weak' | 'on-dark-strong' | 'on-dark-subtle' | 'on-dark-weak' | 'error' | 'inherit';
   component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span' | 'b' | 'i' | 'li' | 'div';
   variant?: 'heading-1' | 'heading-2' | 'heading-3' | 'heading-4' | 'headline' | 'body' | 'body-highlight' | 'small' | 'small-highlight' | 'caption' | 'inherit';
   whiteSpace?: 'normal' | 'nowrap';
   container?: boolean;
   maxLines?: 'disabled' | '1' | '2' | '3' | '4' | '5' | '6';
   underline?: boolean;
   inline?: boolean;
   children: ReactNode;
}

export const Typography: FC<Props> = ({ align = 'left', color, component = 'span', variant = 'body', whiteSpace = 'normal', container = false, maxLines = 'disabled', underline = false, inline = false, children }) => {
   const defaultColors = {
      'heading-1': 'primary',
      'heading-2': 'primary',
      'heading-3': 'primary',
      'heading-4': 'primary',
      headline: 'primary',
      body: 'strong',
      'body-highlight': 'strong',
      small: 'strong',
      'small-highlight': 'strong',
      caption: 'subtle',
      inherit: 'inherit'
   };

   const typographyVariants = {
      'heading-1': 'phone:typography-component-data-display-typography-mobile-h1 tab-port:typography-component-data-display-typography-tablet-h1 tab-land:typography-component-data-display-typography-tablet-h1 typography-component-data-display-typography-desktop-h1',
      'heading-2': 'phone:typography-component-data-display-typography-mobile-h2 tab-port:typography-component-data-display-typography-tablet-h2 tab-land:typography-component-data-display-typography-tablet-h2 typography-component-data-display-typography-desktop-h2',
      'heading-3': 'phone:typography-component-data-display-typography-mobile-h3 tab-port:typography-component-data-display-typography-tablet-h3 tab-land:typography-component-data-display-typography-tablet-h3 typography-component-data-display-typography-desktop-h3',
      'heading-4': 'phone:typography-component-data-display-typography-mobile-h4 tab-port:typography-component-data-display-typography-tablet-h4 tab-land:typography-component-data-display-typography-tablet-h4 typography-component-data-display-typography-desktop-h4',
      headline: 'phone:typography-component-data-display-typography-mobile-headline tab-port:typography-component-data-display-typography-tablet-headline tab-land:typography-component-data-display-typography-tablet-headline typography-component-data-display-typography-desktop-headline',
      body: 'phone:typography-component-data-display-typography-mobile-body-regular tab-port:typography-component-data-display-typography-tablet-body-regular tab-land:typography-component-data-display-typography-tablet-body-regular typography-component-data-display-typography-desktop-body-regular',
      'body-highlight': 'phone:typography-component-data-display-typography-mobile-body-bold tab-port:typography-component-data-display-typography-tablet-body-bold tab-land:typography-component-data-display-typography-tablet-body-bold typography-component-data-display-typography-desktop-body-bold',
      small: 'phone:typography-component-data-display-typography-mobile-label-regular tab-port:typography-component-data-display-typography-tablet-label-regular tab-land:typography-component-data-display-typography-tablet-label-regular typography-component-data-display-typography-desktop-label-regular',
      'small-highlight':
         'phone:typography-component-data-display-typography-mobile-label-bold tab-port:typography-component-data-display-typography-tablet-label-bold tab-land:typography-component-data-display-typography-tablet-label-bold typography-component-data-display-typography-desktop-label-bold',
      caption:
         'phone:typography-component-data-display-typography-mobile-caption-regular tab-port:typography-component-data-display-typography-tablet-caption-regular tab-land:typography-component-data-display-typography-tablet-caption-regular typography-component-data-display-typography-desktop-caption-regular'
   };

   const presentColor = color || defaultColors[variant];

   const testId = `typography--${variant}--${presentColor}${container ? '--contained' : ''}`;

   const classes = classNames(
      'transition-all !underline-none',
      align === 'left' && 'text-left',
      align === 'center' && 'text-center',
      align === 'right' && 'text-right',
      whiteSpace === 'nowrap' && 'whitespace-nowrap',
      variant !== 'inherit' && typographyVariants[variant],
      presentColor === 'primary' && '!text-component-data-display-typography-label-color-text-primary',
      presentColor === 'secondary' && '!text-component-data-display-typography-label-color-text-secondary',
      presentColor === 'tertiary' && '!text-component-data-display-typography-label-color-text-tertiary',
      presentColor === 'strong' && '!text-component-data-display-typography-label-color-text-default-strong',
      presentColor === 'subtle' && '!text-component-data-display-typography-label-color-text-default-subtle',
      presentColor === 'weak' && '!text-component-data-display-typography-label-color-text-default-weak',
      presentColor === 'inverse-strong' && '!text-component-data-display-typography-label-color-text-on-primary-strong',
      presentColor === 'inverse-subtle' && '!text-component-data-display-typography-label-color-text-on-primary-subtle',
      presentColor === 'inverse-weak' && '!text-component-data-display-typography-label-color-text-on-primary-weak',
      presentColor === 'on-dark-strong' && '!text-component-data-display-typography-label-color-text-on-dark-strong',
      presentColor === 'on-dark-subtle' && '!text-component-data-display-typography-label-color-text-on-dark-subtle',
      presentColor === 'on-dark-weak' && '!text-component-data-display-typography-label-color-text-on-dark-weak',
      presentColor === 'error' && '!text-component-data-display-typography-label-color-text-error',
      presentColor === 'inherit' && '!text-inherit',
      container && (variant.includes('heading') || variant === 'headline') && 'typography-container-heading',
      container && !(variant.includes('heading') || variant === 'headline') && 'typography-container',
      maxLines === '1' && 'line-clamp-1',
      maxLines === '2' && 'line-clamp-2',
      maxLines === '3' && 'line-clamp-3',
      maxLines === '4' && 'line-clamp-4',
      maxLines === '5' && 'line-clamp-5',
      maxLines === '6' && 'line-clamp-6',
      component !== 'li' && component !== 'span' && component !== 'b' && 'block',
      underline && 'underline',
      inline && 'inline'
   );

   return (
      <>
         {component === 'h1' && (
            <h1 data-testid={testId} className={classes}>
               {children}
            </h1>
         )}
         {component === 'h2' && (
            <h2 data-testid={testId} className={classes}>
               {children}
            </h2>
         )}
         {component === 'h3' && (
            <h3 data-testid={testId} className={classes}>
               {children}
            </h3>
         )}
         {component === 'h4' && (
            <h4 data-testid={testId} className={classes}>
               {children}
            </h4>
         )}
         {component === 'h5' && (
            <h5 data-testid={testId} className={classes}>
               {children}
            </h5>
         )}
         {component === 'p' && (
            <p data-testid={testId} className={classes}>
               {children}
            </p>
         )}
         {component === 'span' && (
            <span data-testid={testId} className={classes}>
               {children}
            </span>
         )}
         {component === 'b' && (
            <b data-testid={testId} className={classes}>
               {children}
            </b>
         )}
         {component === 'i' && (
            <i data-testid={testId} className={classes}>
               {children}
            </i>
         )}
         {component === 'li' && (
            <li data-testid={testId} className={classes}>
               {children}
            </li>
         )}
         {component === 'div' && (
            <div data-testid={testId} className={classes}>
               {children}
            </div>
         )}
      </>
   );
};
