'use client';

/* eslint-disable react/button-has-type */
import { FC, ReactNode } from 'react';

import dataAttributes from '../../../../utils/attributes/attributes';
import { Badge } from '../../../badge/components/Badge/Badge';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   id?: string;
   variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'error';
   leadingIcon?: string;
   trailingIcon?: string;
   badge?: string;
   disabled?: boolean;
   type?: 'button' | 'submit' | 'reset';
   fullWidth?: boolean;
   attributes?: Record<string, string>;
   onClick?: () => void;
   children: ReactNode;
}

export const Button: FC<Props> = ({ id, variant = 'primary', leadingIcon, trailingIcon, badge, disabled = false, type = 'button', fullWidth = false, attributes = {}, onClick, children }) => {
   const badgeVariant = () => {
      switch (variant) {
         case 'primary':
            return 'secondary';
         case 'secondary':
            return 'primary';
         case 'tertiary':
            return 'primary';
         case 'quaternary':
            return 'secondary';
         case 'error':
            return 'secondary';
         /* istanbul ignore next */
         default:
            return 'secondary';
      }
   };

   /* istanbul ignore next */
   const handleClick = () => !disabled && onClick?.();

   const content = () => (
      <div className="gap-component-input-button-container-spacing-gap flex items-center justify-center">
         {leadingIcon && (
            <div data-testid="button--leading-icon">
               <Icon icon={leadingIcon} />
            </div>
         )}
         <Typography variant="body-highlight" color="inherit" whiteSpace="nowrap">
            {children}
         </Typography>
         {badge && (
            <div data-testid={`button-badge--${badgeVariant()}`}>
               <Badge variant={badgeVariant()}>{badge}</Badge>
            </div>
         )}
         {trailingIcon && (
            <div data-testid="button--trailing-icon">
               <Icon icon={trailingIcon} />
            </div>
         )}
      </div>
   );

   return (
      <div className="contents">
         {variant === 'primary' && (
            <button
               id={id}
               type={type}
               onClick={handleClick}
               disabled={disabled}
               data-testid="button--primary"
               {...dataAttributes(attributes)}
               className={`min-h-component-input-button-container-sizing-min-height rounded-component-input-button-container-border-radius bg-component-input-button-container-color-background-primary-enabled disabled:bg-component-input-button-state-layer-color-background-primary-disabled group box-border flex items-stretch overflow-hidden
                      ${fullWidth ? 'w-full' : ''}
                  `}
            >
               <div
                  data-testid="button-state-layer--primary"
                  className={`px-component-input-button-container-spacing-padding-x py-component-input-button-container-spacing-padding-y pointer-events-none flex w-full items-center justify-center transition-all ${
                     disabled
                        ? 'text-component-input-button-label-color-text-primary-disabled'
                        : 'cursor-pointer text-component-input-button-label-color-text-primary-enabled group-hover:bg-component-input-button-state-layer-color-background-primary-hover group-focus-visible:bg-component-input-button-state-layer-color-background-primary-focused group-active:bg-component-input-button-state-layer-color-background-primary-pressed'
                  }`.replace(/\s+/g, ' ')}
               >
                  {content()}
               </div>
            </button>
         )}

         {variant === 'secondary' && (
            <button
               id={id}
               type={type}
               onClick={handleClick}
               disabled={disabled}
               data-testid="button--secondary"
               {...dataAttributes(attributes)}
               className={`border-component-input-button-container-color-border-secondary-enabled disabled:border-component-input-button-container-color-border-secondary-disabled border-component-input-button-container-border-width-secondary min-h-component-input-button-container-sizing-min-height rounded-component-input-button-container-border-radius bg-component-input-button-container-color-background-secondary-enabled group box-border flex items-stretch overflow-hidden
                      ${fullWidth ? 'w-full' : ''}
                  `}
            >
               <div
                  data-testid="button-state-layer--secondary"
                  className={`px-component-input-button-container-spacing-padding-x py-component-input-button-container-spacing-padding-y pointer-events-none flex w-full items-center justify-center transition-all ${
                     disabled
                        ? 'text-component-input-button-label-color-text-secondary-disabled'
                        : 'cursor-pointer text-component-input-button-label-color-text-secondary-enabled group-hover:bg-component-input-button-state-layer-color-background-secondary-hover group-focus-visible:bg-component-input-button-state-layer-color-background-secondary-focused group-active:bg-component-input-button-state-layer-color-background-secondary-pressed'
                  }`.replace(/\s+/g, ' ')}
               >
                  {content()}
               </div>
            </button>
         )}

         {variant === 'tertiary' && (
            <button
               id={id}
               type={type}
               onClick={handleClick}
               disabled={disabled}
               data-testid="button--tertiary"
               {...dataAttributes(attributes)}
               className={`min-h-component-input-button-container-sizing-min-height rounded-component-input-button-container-border-radius bg-component-input-button-container-color-background-tertiary-enabled group box-border flex items-stretch overflow-hidden
                      ${fullWidth ? 'w-full' : ''}
                  `}
            >
               <div
                  data-testid="button-state-layer--tertiary"
                  className={`px-component-input-button-container-spacing-padding-x py-component-input-button-container-spacing-padding-y pointer-events-none flex w-full items-center justify-center transition-all ${
                     disabled
                        ? 'text-component-input-button-label-color-text-tertiary-disabled'
                        : 'cursor-pointer text-component-input-button-label-color-text-tertiary-enabled group-hover:bg-component-input-button-state-layer-color-background-tertiary-hover group-focus-visible:bg-component-input-button-state-layer-color-background-tertiary-focused group-active:bg-component-input-button-state-layer-color-background-tertiary-pressed'
                  }`.replace(/\s+/g, ' ')}
               >
                  {content()}
               </div>
            </button>
         )}

         {variant === 'quaternary' && (
            <button
               id={id}
               type={type}
               onClick={handleClick}
               disabled={disabled}
               data-testid="button--quaternary"
               {...dataAttributes(attributes)}
               className={`border-component-input-button-container-color-border-ghost-enabled disabled:border-component-input-button-container-color-border-ghost-disabled border-component-input-button-container-border-width-secondary min-h-component-input-button-container-sizing-min-height rounded-component-input-button-container-border-radius group box-border flex items-stretch overflow-hidden
                      ${fullWidth ? 'w-full' : ''}
                  `}
            >
               <div
                  data-testid="button-state-layer--quaternary"
                  className={`px-component-input-button-container-spacing-padding-x py-component-input-button-container-spacing-padding-y pointer-events-none flex w-full items-center justify-center transition-all ${
                     disabled
                        ? 'text-component-input-button-label-color-text-ghost-disabled'
                        : 'cursor-pointer text-component-input-button-label-color-text-ghost-enabled group-hover:bg-component-input-button-state-layer-color-background-skeleton-hover group-focus-visible:bg-component-input-button-state-layer-color-background-skeleton-focused group-active:bg-component-input-button-state-layer-color-background-skeleton-pressed'
                  }`.replace(/\s+/g, ' ')}
               >
                  {content()}
               </div>
            </button>
         )}

         {variant === 'error' && (
            <button
               id={id}
               type={type}
               onClick={handleClick}
               disabled={disabled}
               data-testid="button--error"
               {...dataAttributes(attributes)}
               className={`min-h-component-input-button-container-sizing-min-height rounded-component-input-button-container-border-radius bg-component-input-button-container-color-background-danger-enabled disabled:bg-component-input-button-state-layer-color-background-danger-disabled group box-border flex  items-stretch overflow-hidden
                      ${fullWidth ? 'w-full' : ''}
                  `}
            >
               <div
                  data-testid="button-state-layer--error"
                  className={`px-component-input-button-container-spacing-padding-x py-component-input-button-container-spacing-padding-y pointer-events-none flex w-full items-center justify-center transition-all ${
                     disabled
                        ? 'text-component-input-button-label-color-text-danger-disabled'
                        : 'cursor-pointer text-component-input-button-label-color-text-danger-enabled group-hover:bg-component-input-button-state-layer-color-background-danger-hover group-focus-visible:bg-component-input-button-state-layer-color-background-danger-focused group-active:bg-component-input-button-state-layer-color-background-danger-pressed'
                  }`.replace(/\s+/g, ' ')}
               >
                  {content()}
               </div>
            </button>
         )}
      </div>
   );
};
