import { ReactNode, forwardRef, useMemo } from 'react';

import classNames from 'classnames';

import dataAttributes from '../../../../utils/attributes/attributes';

type BoxSpacing = 'none' | '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '13xl';
type BoxOverflow = 'visible' | 'hidden' | 'scroll' | 'auto' | 'clip';

type BoxShorthandSpacing = BoxSpacing | { top?: BoxSpacing; right?: BoxSpacing; bottom?: BoxSpacing; left?: BoxSpacing; x?: BoxSpacing; y?: BoxSpacing };

interface Props {
   id?: string;
   tag?: 'div' | 'section';
   content?: 'start' | 'between' | 'center' | 'end' | 'around' | 'stretch';
   direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
   gap?: BoxSpacing;
   items?: 'start' | 'center' | 'end' | 'stretch' | 'baseline';
   justify?: 'start' | 'between' | 'center' | 'end' | 'around';
   wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
   position?: 'relative' | 'absolute' | 'fixed' | 'sticky';
   top?: string | number;
   left?: string | number;
   bottom?: string | number;
   right?: string | number;
   insetX?: string | number;
   insetY?: string | number;
   inset?: string | number;
   minWidth?: string | number;
   width?: string | number;
   maxWidth?: string | number;
   minHeight?: string | number;
   height?: string | number;
   maxHeight?: string | number;
   wordBreak?: 'normal' | 'all' | 'keep' | 'words';
   overflow?: BoxOverflow;
   overflowX?: BoxOverflow;
   overflowY?: BoxOverflow;
   spacing?: BoxSpacing | BoxShorthandSpacing;
   margin?: BoxSpacing | BoxShorthandSpacing;
   borderRadius?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'full';
   className?: string;
   zIndex?: string;
   background?: string;
   backgroundColor?: 'gradient-fade-out-black' | 'gradient-fade-out-white' | 'gradient-primary' | 'scrimmed-alternate' | 'scrimmed-default' | 'scrimmed-primary' | 'scrimmed-theme';
   rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'full';
   shadow?: boolean;
   attributes?: Record<string, string>;
   onClick?: () => void;
   children?: ReactNode | Array<ReactNode>;
}

/* istanbul ignore next */
const calculateSpacing = (spacing: BoxSpacing | BoxShorthandSpacing, type: 'padding' | 'margin') => {
   const cssVarName = (size: BoxSpacing) => `var(--component-layout-box-spacing-padding-${size.replace('-', '')})`;
   const cssValue = (size: BoxSpacing) => (size.indexOf('-') === 0 ? `calc(${cssVarName(size)} * -1)` : cssVarName(size));

   if (typeof spacing === 'string') return { [type]: cssValue(spacing) };

   return { [type]: `${cssValue(spacing?.y || spacing?.top || 'none')} ${cssValue(spacing?.x || spacing?.right || 'none')} ${cssValue(spacing?.y || spacing?.bottom || 'none')} ${cssValue(spacing?.x || spacing?.left || 'none')}` };
};

const getInset = (value: string | number | undefined, fallbackValue: string | number | undefined) => {
   if (value !== undefined) return value;
   return fallbackValue;
};

export const Box = forwardRef<HTMLDivElement, Props>(
   (
      {
         id,
         tag = 'div',
         content = 'start',
         direction = 'row',
         gap = 'lg',
         items = 'stretch',
         justify = 'start',
         wrap = 'nowrap',
         position,
         top,
         left,
         bottom,
         right,
         insetX,
         insetY,
         inset,
         minWidth,
         width = 'auto',
         maxWidth,
         minHeight,
         height = 'auto',
         maxHeight,
         overflow,
         overflowX,
         overflowY,
         spacing = 'none',
         margin = 'none',
         wordBreak,
         borderRadius = 'none',
         className,
         zIndex,
         background,
         backgroundColor,
         rounded,
         shadow,
         attributes = {},
         onClick,
         children
      },
      ref
   ) => {
      const calculatedMargins = useMemo(() => calculateSpacing(margin, 'margin'), [margin]);
      const calculatedPaddings = useMemo(() => calculateSpacing(spacing, 'padding'), [spacing]);

      const cssClasses = classNames(
         'flex',
         content === 'around' && 'content-around',
         content === 'between' && 'content-between',
         content === 'center' && 'content-center',
         content === 'end' && 'content-end',
         content === 'start' && 'content-start',
         content === 'stretch' && 'content-stretch',
         direction === 'column' && 'flex-col',
         direction === 'column-reverse' && 'flex-col-reverse',
         direction === 'row' && 'flex-row',
         direction === 'row-reverse' && 'flex-row-reverse',
         gap === '2xl' && 'gap-component-layout-box-spacing-gap-2xl',
         gap === '2xs' && 'gap-component-layout-box-spacing-gap-2xs',
         gap === '3xl' && 'gap-component-layout-box-spacing-gap-3xl',
         gap === '3xs' && 'gap-component-layout-box-spacing-gap-3xs',
         gap === 'lg' && 'gap-component-layout-box-spacing-gap-lg',
         gap === 'md' && 'gap-component-layout-box-spacing-gap-md',
         gap === 'none' && 'gap-component-layout-box-spacing-gap-none',
         gap === 'sm' && 'gap-component-layout-box-spacing-gap-sm',
         gap === 'xl' && 'gap-component-layout-box-spacing-gap-xl',
         gap === 'xs' && 'gap-component-layout-box-spacing-gap-xs',
         items === 'baseline' && 'items-baseline',
         items === 'center' && 'items-center',
         items === 'end' && 'items-end',
         items === 'start' && 'items-start',
         items === 'stretch' && 'items-stretch',
         justify === 'around' && 'justify-around',
         justify === 'between' && 'justify-between',
         justify === 'center' && 'justify-center',
         justify === 'end' && 'justify-end',
         justify === 'start' && 'justify-start',
         wrap === 'nowrap' && 'flex-nowrap',
         wrap === 'wrap' && 'flex-wrap',
         wrap === 'wrap-reverse' && 'flex-wrap-reverse',
         wordBreak === 'all' && 'break-all',
         wordBreak === 'keep' && 'break-keep',
         wordBreak === 'normal' && 'break-normal',
         wordBreak === 'words' && 'break-words',
         borderRadius === 'xs' && 'rounded-brand-border-radius-xs',
         borderRadius === 'sm' && 'rounded-brand-border-radius-sm',
         borderRadius === 'md' && 'rounded-brand-border-radius-md',
         borderRadius === 'lg' && 'rounded-brand-border-radius-lg',
         borderRadius === 'full' && 'rounded-brand-border-radius-full',
         onClick && 'cursor-pointer',
         rounded === 'xs' && 'rounded-component-layout-box-border-radius-xs',
         rounded === 'sm' && 'rounded-component-layout-box-border-radius-sm',
         rounded === 'md' && 'rounded-component-layout-box-border-radius-md',
         rounded === 'lg' && 'rounded-component-layout-box-border-radius-lg',
         rounded === 'full' && 'rounded-component-layout-box-border-radius-full',
         shadow && 'shadow-component-layout-box-box-shadow-default hover:shadow-component-layout-box-box-shadow-hover',
         backgroundColor === 'gradient-fade-out-black' && 'bg-gradient-to-t from-zinc-900 to-transparent',
         backgroundColor === 'gradient-fade-out-white' && 'bg-component-surfaces-overlay-container-color-background-gradient-fade-out',
         backgroundColor === 'gradient-primary' && 'bg-component-surfaces-overlay-container-color-background-gradient-primary',
         backgroundColor === 'scrimmed-alternate' && 'bg-component-surfaces-overlay-container-color-background-scrimmed-alternate',
         backgroundColor === 'scrimmed-default' && 'bg-component-surfaces-overlay-container-color-background-scrimmed-default',
         backgroundColor === 'scrimmed-primary' && 'bg-component-surfaces-overlay-container-color-background-scrimmed-primary',
         backgroundColor === 'scrimmed-theme' && 'bg-component-surfaces-overlay-container-color-background-scrimmed-theme',
         className
      );

      const cssStyles = {
         minWidth,
         minHeight,
         width,
         height,
         maxWidth,
         maxHeight,
         overflow,
         overflowX,
         overflowY,
         position,
         inset,
         zIndex,
         background,
         left: getInset(left, insetX),
         top: getInset(top, insetY),
         right: getInset(right, insetX),
         bottom: getInset(bottom, insetY),
         ...calculatedMargins,
         ...calculatedPaddings
      };

      return tag === 'section' ? (
         <section role="presentation" id={id} data-testid="box-section" ref={ref} className={cssClasses} style={cssStyles} {...dataAttributes(attributes)} onClick={onClick} onKeyDown={onClick}>
            {children}
         </section>
      ) : (
         <div role="presentation" id={id} data-testid="box-div" ref={ref} className={cssClasses} style={cssStyles} {...dataAttributes(attributes)} onClick={onClick} onKeyDown={onClick}>
            {children}
         </div>
      );
   }
);
