import { FC, ReactNode } from 'react';

import { BreakpointSwitch } from '../../../breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';

interface Props {
   height?: string;
   width?: string;
   phone?: string;
   tablet?: string;
   desktop?: string;
   aspectRatio?: string;
   useContainerQuery?: boolean;
   children: ReactNode;
}

export const AspectRatio: FC<Props> = ({ phone, tablet, desktop, aspectRatio, useContainerQuery = false, height, width, children }) => (
   <>
      <BreakpointSwitch includeViews={['phone']} useContainerQuery={useContainerQuery}>
         <div data-testid="aspect-ratio--phone" className="flex justify-center overflow-hidden" style={{ aspectRatio: phone ?? aspectRatio, height, width }}>
            {children}
         </div>
      </BreakpointSwitch>
      <BreakpointSwitch includeViews={['tab-port', 'tab-land']} useContainerQuery={useContainerQuery}>
         <div data-testid="aspect-ratio--tablet" className="flex justify-center overflow-hidden" style={{ aspectRatio: tablet ?? aspectRatio, height, width }}>
            {children}
         </div>
      </BreakpointSwitch>
      <BreakpointSwitch includeViews={['desktop']} useContainerQuery={useContainerQuery}>
         <div data-testid="aspect-ratio--desktop" className="flex justify-center overflow-hidden" style={{ aspectRatio: desktop ?? aspectRatio, height, width }}>
            {children}
         </div>
      </BreakpointSwitch>
   </>
);
