import { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props extends PropsWithChildren {
   variant?: 'primary' | 'secondary' | 'disabled' | 'success' | 'error' | 'warning' | 'info' | 'neutral';
   weight?: 'regular' | 'bold';
   size?: 'regular' | 'extra-large';
   leadingIcon?: string;
   trailingIcon?: string;
}

export const Badge: FC<Props> = ({ variant = 'primary', leadingIcon, trailingIcon, weight = 'bold', size = 'regular', children }) => {
   const content = (
      <div
         data-testid="badge-container"
         className={classNames(
            size === 'regular' && 'px-component-data-display-badge-container-spacing-padding-x-regular py-component-data-display-badge-container-spacing-padding-y-regular gap-component-data-display-badge-container-spacing-gap-regular',
            size === 'extra-large' && 'px-component-data-display-badge-container-spacing-padding-x-xl py-component-data-display-badge-container-spacing-padding-y-xl gap-component-data-display-badge-container-spacing-gap-xl',
            'box-border',
            'flex',
            'items-center',
            'whitespace-nowrap',
            'text-center'
         )}
      >
         {leadingIcon && (
            <div data-testid="badge-leading-icon">
               <Icon icon={leadingIcon} size="small" />
            </div>
         )}
         {children && (
            <Typography color="inherit" variant={weight === 'regular' ? 'small' : 'small-highlight'}>
               {children}
            </Typography>
         )}
         {trailingIcon && (
            <div data-testid="badge-trailing-icon">
               <Icon icon={trailingIcon} size="small" />
            </div>
         )}
      </div>
   );

   const container = () => {
      switch (variant) {
         case 'primary':
            return (
               <div
                  data-testid="badge--primary"
                  className="bg-component-data-display-badge-container-color-background-primary rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-primary flex justify-center"
               >
                  {content}
               </div>
            );

         case 'secondary':
            return (
               <div
                  data-testid="badge--secondary"
                  className="bg-component-data-display-badge-container-color-background-inverse-primary rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-inverse-primary flex justify-center"
               >
                  {content}
               </div>
            );

         case 'disabled':
            return (
               <div
                  data-testid="badge--disabled"
                  className="bg-component-data-display-badge-container-color-background-disabled rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-disabled flex justify-center"
               >
                  {content}
               </div>
            );

         case 'success':
            return (
               <div
                  data-testid="badge--success"
                  className="bg-component-data-display-badge-container-color-background-success rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-success flex justify-center"
               >
                  {content}
               </div>
            );

         case 'error':
            return (
               <div
                  data-testid="badge--error"
                  className="bg-component-data-display-badge-container-color-background-danger rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-danger flex justify-center"
               >
                  {content}
               </div>
            );

         case 'warning':
            return (
               <div
                  data-testid="badge--warning"
                  className="bg-component-data-display-badge-container-color-background-warning rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-warning flex justify-center"
               >
                  {content}
               </div>
            );

         case 'info':
            return (
               <div
                  data-testid="badge--info"
                  className="bg-component-data-display-badge-container-color-background-info rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-info flex justify-center"
               >
                  {content}
               </div>
            );

         case 'neutral':
            return (
               <div
                  data-testid="badge--neutral"
                  className="bg-component-data-display-badge-container-color-background-neutral rounded-component-data-display-badge-container-border-radius min-w-component-data-display-badge-container-sizing-min-width text-component-data-display-badge-label-color-text-on-neutral text-center"
               >
                  {content}
               </div>
            );

         /* istanbul ignore next */
         default:
            return null;
      }
   };

   return <div className="w-fit">{container()}</div>;
};
