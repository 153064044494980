import { AnchorHTMLAttributes, FC, ReactNode, RefAttributes } from 'react';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
   RefAttributes<HTMLAnchorElement> & {
      children?: ReactNode;
      underline?: 'none' | 'hover' | 'always';
      disabled?: boolean;
      inactive?: boolean;
      color?: 'primary' | 'light' | 'dark';
      variant?: 'body' | 'body-highlight' | 'small' | 'small-highlight' | 'caption' | 'inherit';
      fullWidth?: boolean;
      noFollow?: boolean;
      component?: React.ElementType;
   };

export const Link: FC<LinkProps> = ({ children, underline = 'always', disabled = false, inactive = false, color = 'primary', variant = 'inherit', noFollow = false, fullWidth = false, component, ...props }) => {
   const typographyVariants = {
      body: 'phone:typography-component-data-display-link-text-mobile-body-link tab-port:typography-component-data-display-link-text-tablet-body-link tab-land:typography-component-data-display-link-text-tablet-body-link typography-component-data-display-link-text-desktop-body-link',
      'body-highlight':
         'phone:typography-component-data-display-link-text-mobile-body-link-bold tab-port:typography-component-data-display-link-text-tablet-body-link-bold tab-land:typography-component-data-display-link-text-tablet-body-link-bold typography-component-data-display-link-text-desktop-body-link-bold',
      small: 'phone:typography-component-data-display-link-text-mobile-label-link tab-port:typography-component-data-display-link-text-tablet-label-link tab-land:typography-component-data-display-link-text-tablet-label-link typography-component-data-display-link-text-desktop-label-link',
      'small-highlight':
         'phone:typography-component-data-display-link-text-mobile-label-link-bold tab-port:typography-component-data-display-link-text-tablet-label-link-bold tab-land:typography-component-data-display-link-text-tablet-label-link-bold typography-component-data-display-link-text-desktop-label-link-bold',
      caption: 'phone:typography-component-data-display-link-text-mobile-caption-link tab-port:typography-component-data-display-link-text-tablet-caption-link tab-land:typography-component-data-display-link-text-tablet-caption-link typography-component-data-display-link-text-desktop-caption-link',
      inherit: ''
   };

   // eslint-disable-next-line jsx-a11y/anchor-has-content
   const LinkComponent = component ?? (({ ...allProps }: LinkProps) => <a {...allProps} />);

   return (
      <LinkComponent
         {...props}
         data-testid={`link--${color}--${variant}${disabled ? '--disabled' : ''}${inactive ? '--inactive' : ''}${noFollow ? '--no-follow' : ''}`}
         rel={noFollow ? 'nofollow' : undefined}
         tabIndex={disabled || inactive ? -1 : undefined}
         className={`cursor-pointer
           ${underline === 'hover' && !inactive ? 'hover:underline' : ''}
           ${disabled || inactive ? 'pointer-events-none' : ''}
           ${underline === 'always' && !inactive ? 'underline' : ''}
            ${fullWidth ? 'w-full' : ''}
           ${
              color === 'primary' && !disabled && !inactive
                 ? 'text-component-data-display-link-label-color-text-default-enabled visited:text-component-data-display-link-label-color-text-default-visited hover:text-component-data-display-link-label-color-text-default-hover active:text-component-data-display-link-label-color-text-default-pressed'
                 : ''
           }
           ${color === 'primary' && disabled ? 'text-component-data-display-link-label-color-text-default-disabled' : ''}
           ${color === 'primary' && inactive ? 'text-component-data-display-link-label-color-text-default-inactive' : ''}
           ${
              color === 'light' && !disabled && !inactive
                 ? 'text-component-data-display-link-label-color-text-on-dark-enabled visited:text-component-data-display-link-label-color-text-on-dark-visited hover:text-component-data-display-link-label-color-text-on-dark-hover active:text-component-data-display-link-label-color-text-on-dark-pressed'
                 : ''
           }
           ${color === 'light' && disabled ? 'text-component-data-display-link-label-color-text-on-dark-disabled' : ''}
           ${color === 'light' && inactive ? 'text-component-data-display-link-label-color-text-on-dark-inactive' : ''}
           ${color === 'dark' ? 'text-component-data-display-link-label-color-text-on-light-default' : ''}
           ${typographyVariants[variant]}
        `.replace(/\s+/g, ' ')}
      >
         {children}
      </LinkComponent>
   );
};
